<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>
              Détail
              {{ this.$store.getters["Auth/get_selected_notice"].notice.noticeType.description }}
            </h4>
            <v-spacer></v-spacer>
            <v-icon
              medium
              :color="this.$store.getters['Auth/get_selected_notice'].notice.colorState.colorCode"
              >mdi-circle</v-icon
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Objet de l’avis publié :</b>
            {{ this.$store.getters["Auth/get_selected_notice"].notice.object }}
            <v-spacer class="my-1"></v-spacer>
            <b>Date/heure de publication de l’avis publié :</b>
            {{
              this.$store.getters["Auth/get_selected_notice"].notice.publiDate
                | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}
            <div
              v-if="
                this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 1 &&
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 8 &&
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 9
              "
            >
              <v-spacer class="my-1"></v-spacer>
              <b
                v-if="
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 6 &&
                    this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 7
                "
                >Date/heure limite de dépôt des expressions d’Intérêts :</b
              >
              <b
                v-if="
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 6 ||
                    this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 7
                "
                >Date/heure limite de dépôt des expressions d’Intérêts</b
              >
              {{
                this.$store.getters["Auth/get_selected_notice"].notice.deadline
                  | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
              }}
              <v-spacer class="my-1"></v-spacer>
              <div
                v-if="
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 6 &&
                    this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 7 &&
                    this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 2
                "
              >
                <b>Date d'ouverture des offres :</b>
                {{
                  this.$store.getters["Auth/get_selected_notice"].notice.publiOuverture
                    | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                }}
              </div>
            </div>
            <v-spacer class="my-1"></v-spacer>
          </v-card-text>
        </v-card>
        <v-card v-if="this.$store.getters['Auth/get_selected_notice'].notice.ppm" class="pa-2 mt-4">
          <v-card-title>
            <h4>Plan de Passation des Marchés concerné par l’avis publié</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item class="block text-truncate">
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text text-truncate font-weight-bold"
                    v-text="this.$store.getters['Auth/get_selected_notice'].notice.ppm.title"
                  ></v-list-item-title>

                  <v-list-item-subtitle class="text-truncate secondary--text">{{
                    this.$store.getters["Auth/get_selected_notice"].notice.ppm.publishDate
                      | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="
            this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 8 ||
              this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 9
          "
          class="pa-2 mt-4"
        >
          <v-card-title>
            <h4>Dossier rattaché à l’avis publié</h4>
          </v-card-title>
          <v-card-text>
            <v-list-item class="block text-truncate">
              <v-list-item-content>
                <v-list-item-title class="primary--text text-truncate font-weight-bold">
                  {{
                    this.$store.getters["Auth/get_selected_notice"].notice.attache_document.object
                  }}
                </v-list-item-title>

                <v-list-item-subtitle class="text-truncate secondary--text">{{
                  this.$store.getters["Auth/get_selected_notice"].notice.attache_document.publiDate
                    | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
        <v-card
          class="pa-2 mt-4"
          v-if="this.$store.getters['Auth/get_selected_notice'].notice.documentList.length"
        >
          <v-card-title>
            <h4>Dossier rattaché à l’avis publié</h4>
            <v-spacer></v-spacer>
            <v-chip class="ma-2">{{
              this.$store.getters["Auth/get_selected_notice"].notice.documentList.length
            }}</v-chip>
          </v-card-title>
          <v-card-text>
            <v-row v-if="this.$store.getters['Auth/get_selected_notice'].notice.documentList">
              <v-list-item
                class="block text-truncate"
                v-for="item in this.$store.getters['Auth/get_selected_notice'].notice.documentList"
                :key="item.id"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text text-truncate font-weight-bold"
                    v-text="item.object"
                  ></v-list-item-title>

                  <v-list-item-subtitle class="text-truncate secondary--text">{{
                    item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          class="pa-2 mt-4"
          v-if="
            this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 7 ||
              this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 6
          "
        >
          <v-card-title>
            <h4>Liste des Termes de Références publiés</h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item v-for="item in this.$store.getters['Auth/getTdr']" :key="item.id">
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text text-truncate font-weight-bold"
                    v-text="item.object"
                  ></v-list-item-title>
                  <v-list-item-subtitle class="">
                    {{
                      item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon @click="ShowTheRevision(item.id)">
                    <v-icon color="grey lighten-1">mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="!dialog">
        <v-card>
          <v-card-title>
            <h4>
              Aperçu
              {{ this.$store.getters["Auth/get_selected_notice"].notice.noticeType.description }}
            </h4>
            <v-spacer></v-spacer>
            <v-btn v-if="profile == 2" @click="downloadNotice()" class="primary">
              <v-icon>mdi-download</v-icon>
              TELECHARGER L’AVIS
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height:62em !important;overflow:hidden">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'docx' ||
                  this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_notice'].notice.fileLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'pdf'"
              :src="this.$store.getters['Auth/get_selected_notice'].notice.fileLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="dialog">
        <v-card>
          <v-card-title>
            <h4>{{ Tdr_Ob.object }}</h4>
            <v-spacer></v-spacer>
            <h6>Date : {{ Tdr_Ob.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z") }}</h6>
          </v-card-title>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <VueDocPreview
            v-if="
              Tdr_Ob.fileType == 'doc' ||
                Tdr_Ob.fileType == 'docx' ||
                Tdr_Ob.fileType == 'xls' ||
                Tdr_Ob.fileType == 'xlsx' ||
                Tdr_Ob.fileType == 'XLS' ||
                Tdr_Ob.fileType == 'XLSX'
            "
            :value="Tdr_Ob.fileLink"
            type="office"
          />
          <v-card-text>
            <v-row>
              <vue-friendly-iframe
                v-if="Tdr_Ob.fileType == 'pdf'"
                :src="Tdr_Ob.fileLink"
                class="w-62em"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    VueDocPreview,
    DatePicker
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    Tdr_Ob: "",
    addi: {
      objet: "",
      publi_date: "",
      file: []
    },
    getDownloadValidity: false,
    colorcode: "",
    ppm: [],
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Avis",
        disabled: false,
        href: "/notice"
      },
      {
        text: "Détails de l'avis",
        disabled: true
      }
    ]
  }),
  created() {
    this.$store.dispatch("Auth/loadNoticeTdr", this.id);
    this.$store.dispatch("Auth/getNotice", this.id);
    let Virt = [];
    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.isDownloadValid();
  },
  mounted() {
    this.isDownloadValid();
    this.colorCode;
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    isDownloadValid() {
      if (this.$store.getters["Auth/get_selected_notice"].notice.deadline > new Date()) {
        this.getDownloadValidity = true;
      } else {
        this.getDownloadValidity = false;
      }
    },
    downloadNotice() {
      this.$store.dispatch("Auth/downloadNotice", this.id);
    },
    ShowTheRevision(tdr) {
      let filtered = this.$store.getters["Auth/get_selected_notice"].notice.extensionList.filter(
        function(value, index, arr) {
          return value.id == tdr;
        }
      );
      this.Tdr_Ob = filtered[0];
      this.dialog = !this.dialog;
    },
    addTdr() {
      const data = new FormData();
      data.append("object", this.addi.objet);
      data.append("publi_date", this.addi.publi_date);
      data.append("notice", this.id);
      data.append("file", this.addi.file[0]);
      this.extension_addN(data);
    }
  }
};
</script>
<style>
.mx-datepicker {
  width: auto;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
