var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',[_c('h4',[_vm._v(" Détail "+_vm._s(this.$store.getters["Auth/get_selected_notice"].notice.noticeType.description)+" ")]),_c('v-spacer'),_c('v-icon',{attrs:{"medium":"","color":this.$store.getters['Auth/get_selected_notice'].notice.colorState.colorCode}},[_vm._v("mdi-circle")])],1),_c('v-divider'),_c('v-card-text',[_c('b',[_vm._v("Objet de l’avis publié :")]),_vm._v(" "+_vm._s(this.$store.getters["Auth/get_selected_notice"].notice.object)+" "),_c('v-spacer',{staticClass:"my-1"}),_c('b',[_vm._v("Date/heure de publication de l’avis publié :")]),_vm._v(" "+_vm._s(_vm._f("moment")(this.$store.getters["Auth/get_selected_notice"].notice.publiDate,"dddd, Do MMMM YYYY à HH:mm:ss A Z"))+" "),(
              this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 1 &&
                this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 8 &&
                this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 9
            )?_c('div',[_c('v-spacer',{staticClass:"my-1"}),(
                this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 6 &&
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 7
              )?_c('b',[_vm._v("Date/heure limite de dépôt des expressions d’Intérêts :")]):_vm._e(),(
                this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 6 ||
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 7
              )?_c('b',[_vm._v("Date/heure limite de dépôt des expressions d’Intérêts")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("moment")(this.$store.getters["Auth/get_selected_notice"].notice.deadline,"dddd, Do MMMM YYYY à HH:mm:ss A Z"))+" "),_c('v-spacer',{staticClass:"my-1"}),(
                this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 6 &&
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 7 &&
                  this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status != 2
              )?_c('div',[_c('b',[_vm._v("Date d'ouverture des offres :")]),_vm._v(" "+_vm._s(_vm._f("moment")(this.$store.getters["Auth/get_selected_notice"].notice.publiOuverture,"dddd, Do MMMM YYYY à HH:mm:ss A Z"))+" ")]):_vm._e()],1):_vm._e(),_c('v-spacer',{staticClass:"my-1"})],1)],1),(this.$store.getters['Auth/get_selected_notice'].notice.ppm)?_c('v-card',{staticClass:"pa-2 mt-4"},[_c('v-card-title',[_c('h4',[_vm._v("Plan de Passation des Marchés concerné par l’avis publié")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-list-item',{staticClass:"block text-truncate"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text text-truncate font-weight-bold",domProps:{"textContent":_vm._s(this.$store.getters['Auth/get_selected_notice'].notice.ppm.title)}}),_c('v-list-item-subtitle',{staticClass:"text-truncate secondary--text"},[_vm._v(_vm._s(_vm._f("moment")(this.$store.getters["Auth/get_selected_notice"].notice.ppm.publishDate,"dddd, Do MMMM YYYY à HH:mm:ss A Z")))])],1)],1)],1)],1)],1):_vm._e(),(
          this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 8 ||
            this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 9
        )?_c('v-card',{staticClass:"pa-2 mt-4"},[_c('v-card-title',[_c('h4',[_vm._v("Dossier rattaché à l’avis publié")])]),_c('v-card-text',[_c('v-list-item',{staticClass:"block text-truncate"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text text-truncate font-weight-bold"},[_vm._v(" "+_vm._s(this.$store.getters["Auth/get_selected_notice"].notice.attache_document.object)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-truncate secondary--text"},[_vm._v(_vm._s(_vm._f("moment")(this.$store.getters["Auth/get_selected_notice"].notice.attache_document.publiDate,"dddd, Do MMMM YYYY à HH:mm:ss")))])],1)],1)],1)],1):_vm._e(),(this.$store.getters['Auth/get_selected_notice'].notice.documentList.length)?_c('v-card',{staticClass:"pa-2 mt-4"},[_c('v-card-title',[_c('h4',[_vm._v("Dossier rattaché à l’avis publié")]),_c('v-spacer'),_c('v-chip',{staticClass:"ma-2"},[_vm._v(_vm._s(this.$store.getters["Auth/get_selected_notice"].notice.documentList.length))])],1),_c('v-card-text',[(this.$store.getters['Auth/get_selected_notice'].notice.documentList)?_c('v-row',_vm._l((this.$store.getters['Auth/get_selected_notice'].notice.documentList),function(item){return _c('v-list-item',{key:item.id,staticClass:"block text-truncate"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text text-truncate font-weight-bold",domProps:{"textContent":_vm._s(item.object)}}),_c('v-list-item-subtitle',{staticClass:"text-truncate secondary--text"},[_vm._v(_vm._s(_vm._f("moment")(item.publiDate,"dddd, Do MMMM YYYY à HH:mm:ss A Z")))])],1)],1)}),1):_vm._e()],1)],1):_vm._e(),(
          this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 7 ||
            this.$store.getters['Auth/get_selected_notice'].notice.noticeType.status == 6
        )?_c('v-card',{staticClass:"pa-2 mt-4"},[_c('v-card-title',[_c('h4',[_vm._v("Liste des Termes de Références publiés")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',_vm._l((this.$store.getters['Auth/getTdr']),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text text-truncate font-weight-bold",domProps:{"textContent":_vm._s(item.object)}}),_c('v-list-item-subtitle',{},[_vm._v(" "+_vm._s(_vm._f("moment")(item.publiDate,"dddd, Do MMMM YYYY à HH:mm:ss A Z")))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.ShowTheRevision(item.id)}}},[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-eye")])],1)],1)],1)}),1)],1)],1):_vm._e()],1),(!_vm.dialog)?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v(" Aperçu "+_vm._s(this.$store.getters["Auth/get_selected_notice"].notice.noticeType.description)+" ")]),_c('v-spacer'),(_vm.profile == 2)?_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.downloadNotice()}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" TELECHARGER L’AVIS ")],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"62em !important","overflow":"hidden"}},[(
              this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'doc' ||
                this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'docx' ||
                this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'xls' ||
                this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'xlsx' ||
                this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'XLS' ||
                this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'XLSX'
            )?_c('VueDocPreview',{staticStyle:{"height":"100%"},attrs:{"value":this.$store.getters['Auth/get_selected_notice'].notice.fileLink,"type":"office"}}):_vm._e(),(this.$store.getters['Auth/get_selected_notice'].notice.fileType == 'pdf')?_c('vue-friendly-iframe',{attrs:{"src":this.$store.getters['Auth/get_selected_notice'].notice.fileLink,"frameborder":"0","loading":"lazy"}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.dialog)?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v(_vm._s(_vm.Tdr_Ob.object))]),_c('v-spacer'),_c('h6',[_vm._v("Date : "+_vm._s(_vm._f("moment")(_vm.Tdr_Ob.publiDate,"dddd, Do MMMM YYYY à HH:mm:ss A Z")))])],1),_c('v-divider'),_c('v-divider'),(
            _vm.Tdr_Ob.fileType == 'doc' ||
              _vm.Tdr_Ob.fileType == 'docx' ||
              _vm.Tdr_Ob.fileType == 'xls' ||
              _vm.Tdr_Ob.fileType == 'xlsx' ||
              _vm.Tdr_Ob.fileType == 'XLS' ||
              _vm.Tdr_Ob.fileType == 'XLSX'
          )?_c('VueDocPreview',{attrs:{"value":_vm.Tdr_Ob.fileLink,"type":"office"}}):_vm._e(),_c('v-card-text',[_c('v-row',[(_vm.Tdr_Ob.fileType == 'pdf')?_c('vue-friendly-iframe',{staticClass:"w-62em",attrs:{"src":_vm.Tdr_Ob.fileLink,"frameborder":"0","loading":"lazy"}}):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }