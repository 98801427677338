<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="9" order-md="1" order="2">
        <div class="d-flex flex-column mb-6">
          <v-data-table
            :headers="headers"
            :items="this.$store.getters['Auth/get_public_notice_type']"
            :items-per-page="5"
            :search="search"
            no-data-text="Aucun Avis de Demande de prix"
            @click:row="handleClick"
            class="elevation-1 px-5"
          >
            <template v-slot:top>
              <v-toolbar flat color="white" class="mb-5">
                <v-toolbar-title v-show="$vuetify.breakpoint.mdAndUp">Liste détaillée des avis de sollicitation de Prix</v-toolbar-title>
                <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Recherchez un avis"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:[`item.colorState`]="{ item }">
              <v-icon
                medium
                :color="item.colorState.colorCode"
                >mdi-circle</v-icon
              >
            </template>
            <template v-slot:[`item.deadline`]="{ item }">{{
              item.deadline ? item.deadline : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}</template>

            <template v-slot:[`item.publiDate`]="{ item }">{{
              item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
            }}</template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12" md="3" order-md="2" order="1">
        <v-card>
          <v-card-title>Statuts des avis</v-card-title>
          <v-card-text>
            <v-row class="pl-2">
              <v-icon medium color="yellow" left>mdi-circle</v-icon>
              <v-divider vertical></v-divider>
              <span class="ml-2">Moins de 3 jours</span>
            </v-row>
            <v-row class="pl-2">
              <v-icon medium color="red" left>mdi-circle</v-icon>
              <v-divider vertical></v-divider>
              <span class="ml-2">Expiré</span>
            </v-row>
            <v-row class="pl-2">
              <v-icon medium color="green" left>mdi-circle</v-icon>
              <v-divider vertical></v-divider>
              <span class="ml-2">En cours</span>
            </v-row>
            <v-row class="pl-2">
              <v-icon medium color="grey" left>mdi-circle</v-icon>
              <v-divider vertical></v-divider>
              <span class="ml-2">Non précisé</span>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" v-if="infoHome.data">
          <v-card-title>Statistiques des Additifs et des Termes de Références (TDRs)</v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="pb-2">
                <v-card flat>
                  <v-row>
                    <div class="col pa-3 py-4 orange--text">
                      <h5 class="text-uppercase">Additifs</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1>{{ infoHome.data.extension_addons }}</h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <v-col class="pb-2">
                <v-card flat>
                  <v-row>
                    <div class="col-auto">
                      <div class="pink fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 pink--text">
                      <h5 class="text-uppercase">Tdrs</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1>{{ infoHome.data.extensions_tdrs }}</h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" v-if="!infoHome.data">
          <v-card-title>Statistiques des Additifs et des Termes de Références (TDRs)</v-card-title>
          <v-card-text>
            <v-row>
              <v-col class="pb-2">
                <v-card flat>
                  <v-row>
                    <div class="col pa-3 py-4 orange--text">
                      <h5 class="text-uppercase">Additifs</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1>0</h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
              <v-col class="pb-2">
                <v-card flat>
                  <v-row>
                    <div class="col-auto">
                      <div class="pink fill-height">&nbsp;</div>
                    </div>
                    <div class="col pa-3 py-4 pink--text">
                      <h5 class="text-uppercase">Tdrs</h5>
                      <v-divider class="my-1"></v-divider>
                      <h1>0</h1>
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    search: "",
    headers: [
      {
        text: "Statuts",
        align: "start",
        sortable: false,
        value: "colorState"
      },
      {
        text: "Objet de l'avis",
        sortable: false,
        value: "object"
      },
      {
        text: "Date/heure de publication de l'avis",
        sortable: false,
        value: "publiDate"
      },
      { text: "Date/heure limite de remise des plis (Expression d’Intérêts / Offres / Propositions)", value: "deadline" },
      { text: "Types d'avis", value: "noticeType.title" },
      { text: "PPM", value: "ppm.title", sortable: false }
    ],
    AllNotice: [],
    items: [
      {
        text: "Accueil",
        disabled: false,
        href: "/"
      },
      {
        text: "Tous les avis",
        disabled: true,
        href: "breadcrumbs_link_2"
      }
    ]
  }),
  computed: {
    ...mapGetters({
      infoHome: "Auth/get_all_cnfig_home"
    })
  },
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    const cur_year = new Date().getFullYear();
    this.$store.dispatch("Auth/loadAllPPM", cur_year);
    this.$store.dispatch("Auth/loadPublicNoticeType", { type: 5, variant: null });
  },
  methods: {
    handleClick(value) {
      const { id } = value;
      this.$router.push({ name: "show_p_notice", params: { id } });
    },
    goto(value) {
      const { id } = value;
      this.$router.push({ name: "show_p_notice", params: { id: id } });
    }
  }
};
</script>
<style>
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  -webkit-transition: background-color 3s ease-out;
  -moz-transition: background-color 3s ease-out;
  -o-transition: background-color 3s ease-out;
  transition: background-color 3s ease-out;
  background: #eae7fd;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
