<template>
<v-container fluid>
  <v-row>
    <v-col cols="12" md="9" order-md="1" order="2">
    <v-data-table
      locale="fr"
      :headers="headers"
      @click:row="goto"
      :search="search"
      no-data-text="Aucun Avis disponible"
      :items="this.$store.getters['Auth/getListAvis']"
      sort-by="objet"
      class="elevation-1 mb-5 px-5"
    >
      <template v-slot:[`item.colorState`]="{ item }">
        <v-icon medium :color="item.colorState.colorCode" >mdi-circle</v-icon>
      </template>
      <template v-slot:[`item.deadline`]="{ item }">{{ item.deadline ? item.deadline
         : '--'
       | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")}}</template>

      <template v-slot:[`item.publiDate`]="{ item }">{{
        item.publiDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A Z")
      }}</template>
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-5">
          <v-toolbar-title v-show="$vuetify.breakpoint.mdAndUp">Liste détaillée de tous les avis d’appel à la concurrence</v-toolbar-title>
                      <v-spacer v-show="$vuetify.breakpoint.mdAndUp"></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Recherchez un avis"
                single-line
                hide-details
              ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
    </v-col>
            <v-col cols="12" md="3" order-md="2" order="1">
          <v-card>
            <v-card-title>Statuts des avis</v-card-title>
            <v-card-text>
              <v-row class="pl-2">
                <v-icon medium color="yellow" left>mdi-circle</v-icon>
                <v-divider vertical></v-divider>
                <span class="ml-2">Moins de 3 jours</span>
              </v-row>
              <v-row class="pl-2">
                <v-icon medium color="red" left>mdi-circle</v-icon>
                <v-divider vertical></v-divider>
                <span class="ml-2">Expiré</span>
              </v-row>
              <v-row class="pl-2">
                <v-icon medium color="green" left>mdi-circle</v-icon>
                <v-divider vertical></v-divider>
                <span class="ml-2">En cours</span>
              </v-row>
              <v-row class="pl-2">
                <v-icon medium color="grey" left>mdi-circle</v-icon>
                <v-divider vertical></v-divider>
                <span class="ml-2">Non précisé</span>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-4" v-if="infoHome.data">
            <v-card-title>Statistiques des Additifs et des Termes de Références (TDRs)</v-card-title>
            <v-card-text>
              <v-row>
                <v-col  class="pb-2">
                  <v-card flat>
                    <v-row>
                      <div class="col pa-3 py-4 orange--text">
                        <h5 class="text-uppercase">Additifs</h5>
                        <v-divider class="my-1"></v-divider>
                        <h1>{{infoHome.data.extension_addons}}</h1>
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col class="pb-2">
                  <v-card flat>
                    <v-row>
                      <div class="col-auto">
                        <div class="pink fill-height">&nbsp;</div>
                      </div>
                      <div class="col pa-3 py-4 pink--text">
                        <h5 class="text-uppercase">Tdrs</h5>
                        <v-divider class="my-1"></v-divider>
                        <h1>{{ infoHome.data.extensions_tdrs }}</h1>
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
                    <v-card class="mt-4" v-if="!infoHome.data">
            <v-card-title>Statistiques des Additifs et des Termes de Références (TDRs)</v-card-title>
            <v-card-text>
              <v-row>
                <v-col  class="pb-2">
                  <v-card flat>
                    <v-row>
                      <div class="col pa-3 py-4 orange--text">
                        <h5 class="text-uppercase">Additifs</h5>
                        <v-divider class="my-1"></v-divider>
                        <h1>0</h1>
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col class="pb-2">
                  <v-card flat>
                    <v-row>
                      <div class="col-auto">
                        <div class="pink fill-height">&nbsp;</div>
                      </div>
                      <div class="col pa-3 py-4 pink--text">
                        <h5 class="text-uppercase">Tdrs</h5>
                        <v-divider class="my-1"></v-divider>
                        <h1>0</h1>
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: { DatePicker },
  data: () => ({
    search:"",
    items: [
      {
        text: "Tableau de bord",
        disabled: false,
        href: "/dashboard"
      },
      {
        text: "Mes Avis",
        disabled: true
      }
    ],
    dog: true,
    noticePpm: [
      {
        id: 1,
        title: "Réparation route nationale"
      }
    ],
    noticeType: [],
    notice: {
      object: "",
      publi_date: null,
      open_date: null,
      deadline: null,
      doc:0,
      type: [],
      ppm: [],
      file: []
    },
    dialog: false,
    headers: [
      {
        text: "Statuts",
        align: "start",
        sortable: false,
        value: "colorState"
      },
      {
        text: "Objet de l’avis",
        sortable: false,
        value: "object"
      },
      {
        text: "Date/heure de publication de l’avis",
        sortable: false,
        value: "publiDate"
      },
      { text: " Date/heure limite de remise des plis (Expression d’Intérêts / Offres / Propositions)", value: "deadline" },
      { text: "Types d'avis", value: "noticeType.title" },
      { text: "PPM", value: "ppm.title", sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: "",
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Création d’un nouvel avis d’appel à la concurrence" : "Création du Plan de Passation des Marchés";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    const cur_year = new Date().getFullYear();
    this.$store.dispatch("Auth/loadAllPPM", cur_year);
    this.$store.dispatch("Auth/loadAllNotice");
  },
  computed: {
      ...mapGetters({
        infoHome: "Auth/get_all_cnfig_home",
      }),
    },
  methods: {
    ...mapActions({
      addNotice: "Auth/addNotice",
      loadAllNotice: "Auth/loadAllNotice",
    }),
    open_avis() {
      this.$store.commit("Auth/OPEN_AVIS", true);
    },
    close_avis() {
      this.$store.commit("Auth/OPEN_AVIS", false);
    },
    goto(value) {
      const { id } = value;
      this.$router.push({ name: "show_p_notice", params: { id: id } });
    },
    addthisNotice() {
      const data = new FormData();
      data.append("object", this.notice.object);
      data.append("notice_type", this.notice.type);
      data.append("ppm", this.notice.ppm);
      if(this.notice.publi_date){
        data.append("publi_date", this.notice.publi_date);
      }
      if(this.notice.deadline){
      data.append("deadline", this.notice.deadline);
      }
      if(this.notice.open_date){
      data.append("date_publi_ouverture", this.notice.open_date);
      }
      if(this.notice.doc){
      data.append("doc_id", this.notice.doc);
      }
      if (this.notice.file) {
        data.append("file", this.notice.file[0]);
      } else {
        data.append("file", null);
      }
       console.log(this.notice.open_date);
      this.addNotice(data);
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") && this.desserts.splice(index, 1);
    },
    getPPM(item) {
      console.log(item);
      this.$router.push({ name: "user", params: { ppm: item } });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
</style>
